import { useState } from "react";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import { scroller } from "react-scroll";
import { toast } from "react-toastify";
import Email41 from "../../images//email_41.png";
import Email from "../../images/Email.png";
import Location from "../../images/Location.png";
import Phone from "../../images/Phone.png";
import Ellipse_1 from "../../images/ellipse_1.png";
import Fb from "../../images/facebook_29.png";
import Slider4 from "../../images/icons/Repairs_and_renovations_2.jpg";
import Slider6 from "../../images/icons/Securing_1.jpg";
import Slider2 from "../../images/icons/debris_1.jpg";
import Slider1 from "../../images/icons/lawn_1.jpg";
import Slider3 from "../../images/icons/property_inspection_1.jpg";
import Slider5 from "../../images/icons/winterization_1.jpg";
import instagram_30 from "../../images/instagram_30.png";
import Phone12 from "../../images/iphone_22.png";
import Logo from "../../images/logo.png";
import Linkedin_23 from "../../images/linkedin_23.png";
import Shape_6 from "../../images/shape_6.png";
import sendBrevoEmail from "../../apis/email";
import { emailTemplateContactUs } from "../../utils/template";

const Section1 = () => {
  const [active, setActive] = useState(0);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = async () => {
    if (name !== "" && email !== "" && subject !== "" && message !== "") {
      if (!isValidEmail(email)) {
        toast("Email is invalid! ");
      } else {
        await sendBrevoEmail(
          "POST",
          {},
          {
            to: [
              {
                email:
                  process.env.REACT_APP_BREVO_RECEIVER ||
                  "admin@lopezpropertypreservation.com",
                name: "Lopez Property Preservation Admin",
              },
            ],
            subject: "New Form Submission Received",
            body: emailTemplateContactUs(name, email, subject, message),
          }
        );

        toast("Successfully submit!");
        setSubject("");
        setEmail("");
        setName("");
        setMessage("");
        setIsOpen(false);
      }
    } else if (
      name === "" &&
      email === "" &&
      subject === "" &&
      message === ""
    ) {
      toast("All field is Required!");
    } else if (name === "") {
      toast("Name field is Required!");
    } else if (email === "") {
      toast("Email field is Required!");
    } else if (subject === "") {
      toast("Subject field is Required!");
    } else if (message === "") {
      toast("Message field is Required!");
    }
  };

  const scrollData = (key) => {
    scroller.scrollTo(key, {
      duration: 2000,
      delay: 0,
      smooth: true,
    });
  };
  return (
    <header className="col-9 relative">
      <div className="l-constrained-3 z-10 md:mx-auto md:max-w-[1600px] flex-wrap">
        <div className="wrapper-15">
          <div className="row-3 group justify-center items-center lg:pl-[6.7%]">
            <div className="flex justify-center items-center">
              <img
                loading="lazy"
                className="h-[25px] cursor-pointer "
                src={Phone12}
                alt=""
                width="25"
                height="25"
              />
              <a
                href="tel:904-650-2791"
                className="cursor-pointer text-white leading-[30.2px] text-[16px] max-md:text-[8px] text-medium text-font-plus-jakarta whitespace-nowrap"
              >
                (904) 650-2791 | Ext. 103
              </a>
            </div>
            <div className="flex justify-center items-center ml-8">
              <img
                loading="lazy"
                className="max-md:h-[15px] h-[20px] cursor-pointer"
                src={Email41}
                alt="email"
                width="17"
                height="13"
              />
              <a
                href="mailto:april@lopezpropertypresevation.com"
                className="ml-2 cursor-pointer text-white leading-[30.2px] text-[16px] max-md:text-[10px] text-medium text-font-plus-jakarta"
              >
                april@lopezpropertypresevation.com
              </a>
            </div>

            <div className="hidden">
              <div className="shape-6 max-md:hidden"></div>
              <img
                loading="lazy"
                className="facebook-29 "
                src={Fb}
                alt="facebook"
                width="25"
                height="25"
              />
              <img
                loading="lazy"
                className="instagram-30"
                src={instagram_30}
                alt="instagram"
                width="25"
                height="25"
              />
              <img
                loading="lazy"
                className="linkedin-23"
                src={Linkedin_23}
                alt="linkedin"
                width="25"
                height="25"
              />
            </div>
          </div>
          <div className="rectangle-5 max-lg:hidden"></div>
          <img
            loading="shape"
            className="shape-6-2 max-lg:hidden"
            src={Shape_6}
            alt=""
            width="17"
            height="7"
          />
        </div>
        <div className="flex justify-between items-center md:px-20 lg:px-32 md:-mt-20 lg:-mt-20 max-md:mt-5 max-md:px-3 max-md:pt-0">
          <a href="/" className="cursor-pointer">
            <img
              loading="lazy"
              className="w-[80px] h-[100px] lg:w-[112px] lg:h-[140px] max-md:w-[40px] max-md:h-[40px] max-lg:w-[60px] max-lg:h-[70px] cursor-pointer"
              src={Logo}
              alt="logo"
              width="100%"
              height="100%"
            />
          </a>
          <nav className="flex justify-center items-center">
            <ul className="flex nav-list group font-bold">
              <li>
                <p
                  className="nav-item-1 cursor-pointer"
                  onClick={() => scrollData("element")}
                >
                  Home
                </p>
              </li>
              <li>
                <p
                  className="nav-item-1-2 cursor-pointer"
                  onClick={() => scrollData("element3")}
                >
                  Services
                </p>
              </li>
              <li>
                <p
                  className="nav-item-1-3 cursor-pointer"
                  onClick={() => scrollData("element6")}
                >
                  FAQ
                </p>
              </li>

              <li>
                <p
                  className="nav-item-1-5 cursor-pointer"
                  onClick={() => scrollData("element4")}
                >
                  Careers
                </p>
              </li>
              <li>
                <p
                  className="nav-item-1-6 cursor-pointer"
                  onClick={() => scrollData("element5")}
                >
                  Coverage
                </p>
              </li>
            </ul>
          </nav>
          <button
            className="rounded-rectangle-1-holder cursor-pointer z-10 hidden"
            onClick={() => scrollData("element4")}
          >
            GET IN TOUCH
          </button>
        </div>
        <div className="wrapper-16  max-md:px-3">
          <div className="col">
            <p className="text-5 font-bold">Lopez Property Preservation</p>
            <p className="text-6 font-bold">Protecting Your</p>
            <p className="text-7 font-bold">Property with Care</p>
            <div
              className="rounded-rectangle-1-copy-2-holder cursor-pointer"
              onClick={() => openModal(true)}
            >
              Contact US
            </div>
          </div>
          <img
            className="ellipse-1 max-xl:hidden"
            src={Ellipse_1}
            alt="ellipse"
            loading="lazy"
            width="123"
            height="130"
          />
        </div>
        <div className="row-14 group flex max-md:px-3">
          {[{}, {}, {}, {}, {}, {}].map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer ${
                index === active
                  ? "rounded-rectangle-9-copy "
                  : "rounded-rectangle-9"
              }`}
              onClick={() => setActive(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="absolute top-0 max-md:top-12 left-0 right-0 bottom-0">
        <Carousel
          selectedItem={active}
          showThumbs={false}
          autoPlay
          showStatus={false}
          infiniteLoop
          showIndicators={false}
          showArrows={false}
          onChange={(e) => setActive(e)}
        >
          {[
            { title: Slider1 },
            { title: Slider2 },
            { title: Slider3 },
            { title: Slider4 },
            { title: Slider5 },
            { title: Slider6 },
          ].map((image, index) => (
            <img
              key={index}
              src={image?.title}
              alt={image.imgAlt}
              className="h-full opacity-[70%]"
              width={"100%"}
              height={"100%"}
            />
          ))}
        </Carousel>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        contentLabel="Example Modal"
      >
        <div className="md:w-[750px] max-md:w-[300px] ">
          <div className="flex justify-evenly items-center flex-wrap">
            <div className="hidden flex-col justify-center items-center">
              <img
                loading="lazy"
                src={Location}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Location</label>
              <label className="font-normal">Aventura, FL 33180</label>
            </div>
            <div className="flex flex-col justify-center items-center md:-ml-10">
              <img
                loading="lazy"
                src={Email}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Email Us</label>
              <label className="font-normal">
                april@lopezpropertypresevation.com
              </label>
            </div>
            <div className="flex flex-col justify-center items-center max-md:mt-5">
              <img
                loading="lazy"
                src={Phone}
                alt="location"
                className="w-[50px]"
              />
              <label className="my-2 font-extrabold text-black">Call Us</label>
              <label className="font-normal">(904) 650-2791 | Ext. 103</label>
            </div>
          </div>
          <div className="overflow-auto max-h-[500px] my-5">
            <div className="flex flex-wrap">
              <div className="flex flex-col flex-1 md:mr-5">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
              <div className="flex flex-col flex-1">
                <input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="border rounded-md my-2 p-4 border-red"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <input
                value={subject}
                type="text"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
            <div className="flex flex-col">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message "
                className="border rounded-md my-2 p-4 border-red"
              />
            </div>
          </div>
          <div className="flex gap-5 justify-center ">
            <button
              className="rounded-rectangle-1-copy-4-holder-form font-plus h-[50px]"
              onClick={() => onSubmit()}
            >
              Send Message
            </button>
          </div>
        </div>
      </Modal>
    </header>
  );
};
export default Section1;
